import { bulkElements } from "../configBatch";

export function initOutOfWarrantyEnquiry() {

    //On Service option select
    $("#serviceType").change(function (event) {
        let selectedService = $(event.target).find("option:selected").val();

        if (selectedService && selectedService == "Troubleshoot") {
            $("#troubleshootOptionsWrapper").show();
        }
        else {
            $("#troubleshootOptionsWrapper").hide();
        }
    });

    $("#troubleshootProblemId").change(function (event) {
        let selectedProblemId = $(event.target).find("option:selected").val();
        updateSymptoms(selectedProblemId);

        $("#symptomsSelector").show();
    });
}

function updateSymptoms(selectedProblemId) {
    let symptomList = document.getElementById("symptomId");
    populateSymptomsBasedOnProblem(symptomList, selectedProblemId);
}

function populateSymptomsBasedOnProblem(symptomList, problemId) {
    for (let i = 1; i < symptomList.length; i++) {
        if (symptomList[i].value === problemId) {
            symptomList[i].style.display = "block";
        }
        else {
            symptomList[i].style.display = "none";
        }
    }
}


export function saveOutOfWarrantyEnquiry(){
    const selectedService = $(bulkElements.formOutOfWarrantyEnquiry).find("#serviceType option:selected").text();

    let primaryIssue = 'N/A';
    let symptom = 'N/A';
    if (selectedService == "Troubleshoot") {
        let problemSelect = document.getElementById('troubleshootProblemId');
        primaryIssue = problemSelect?.options[problemSelect.selectedIndex]?.innerHTML;

        let symptomSelect = document.getElementById('symptomId');
        symptom = symptomSelect?.options[symptomSelect.selectedIndex]?.innerHTML;
    }

    const enquiry = {
        serviceType: selectedService,
        primaryIssue: primaryIssue,
        symptom: symptom
    };

    window.state.batches[window.state.currentBatchIndex].outOfWarrantyEnquiry = enquiry;
}