import axios from "axios";
import { elements } from './config';

export const showLoader = () => {
    $(elements.loader)
        .fadeIn('fast');
}

export const removeLoader = () => {
    $(elements.loader)
        .fadeOut('slow');
}

const showErrorMessage = (error, formElement) => {
    let serverErrorText = formElement.attr("data-server-error-text");

    $('.alert .error-message').text(serverErrorText + ` The error text says: ${error}`);
    $('.alert').removeClass('collapse');
}

export const displayError = (error, formElement) => {
    showErrorMessage(error, formElement);
    return false;
}

export const displayScannerNotFoundModal = (error) => {
    sessionStorage.clear();
    $(scannerNotFoundModal).modal('show');
}

export const OffCanvasToggle = (function () {
    return {
        init: function () {
            $('#toggle-sidebar').on('click', function (e) {
                $('.sidebar-container').toggleClass('open');
                e.preventDefault();
            });
        }
    };
})();

export const EqualWidthButtons = (function () {
    let btns = $('.btn.btn-primary');
    return {
        init: function () {
            let widths = btns
                .map(function () {
                    return $(this).width();
                })
                .get(),
                maxWidth = Math.max.apply(null, widths);

            btns.width(maxWidth);
        }
    };
})();

//Get partial views helper functions
const validateForm = (form) => {
    jQuery.validator.addMethod("defaultInvalid", function (value, element) {
        return !(element.value === element.defaultValue);
    }, 'Please select a value different than the default one!');

    jQuery.validator.addMethod("phoneAll", function (phone_number, element) {
        phone_number = phone_number.replace(/\s+/g, "");
        return this.optional(element) || phone_number.length > 9 &&
            phone_number.match(/^((\+[1-9]{1,4}[ \-\.]*)|(\([0-9]{2,3}\)[ \-\.]*)|([0-9]{2,4})[ \-\.]*)*?[0-9]{3,4}?[ \-\.]*[0-9]{3,4}?$/);
    }, "Please specify a valid phone number");

    form.validate({
        normalizer: function (value) {
            // Trim the value of every element
            return $.trim(value);
        },
        errorPlacement: function errorPlacement(error, element) {
            element.before(error);
        },
        rules: {
            'customer[emailConfirm]': {
                equalTo: '#email',
                email: true
            }
        }
    }).settings.ignore = ":disabled,:hidden";
}

const GetPartialViewMarkup = async (partialViewUrl, message) => {
    try {
        const response = await axios.get(partialViewUrl, {
            params: {
                message: message
            }
        });

        return response?.data;
    } catch (error) {
        console.log(error);
    }
};

export const DisplayView = async (viewUrl, viewInitFunction, errorMessage, checkIfFormIsValid = true) => {
    let viewMarkup = '';

    try {
        validateForm($(elements.form))

        if (checkIfFormIsValid) {
            if ($(elements.form).valid() == false) {
                return false;
            }
        }

        showLoader();

        let containerElement = $('#viewContainer');

        //Perform view markup requests until the markup doesn't containt Sitefinity Trial message
        while (viewMarkup == '' || viewMarkup.toLowerCase().includes("you are running a trial version")) {
            viewMarkup = await GetPartialViewMarkup(viewUrl, errorMessage);
        }

        containerElement.html(viewMarkup);

        if (viewInitFunction) {
            viewInitFunction.init();
        }

        return true;
    } catch (error) {
        throw error;
    }
    finally {
        removeLoader();
    }
};