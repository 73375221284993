import { scannersUrl, serialnumbersUrl, scannersKey, scannersAuth, authToken } from "../config";
import { bulkElements, batchIds } from "../configBatch";

export default class ScannerIdentification {
    constructor(){
        this.initOrCleanDeviceCollections();
    }

    initOrCleanDeviceCollections(){
        this.addresses = [];
        this.notRecognizedAddresses = [];

        this.premiumScanners = [];
        this.accidentalScanners = [];
        this.standardScanners = [];
        this.outOfWarrantyScanners = [];

        this.duplicatedAddresses = [];
    }

    init(){
        $(bulkElements.btnTryAgainVerifyDevice).click(() => {this.retryVerifyDevice()});

        $(bulkElements.btnFinishDeviceInput).attr('disabled',true);

        $(bulkElements.txtDevicesInfo).keyup(function(){
            if($(this).val().length !=0)
                $(bulkElements.btnFinishDeviceInput).attr('disabled', false);            
            else
                $(bulkElements.btnFinishDeviceInput).attr('disabled',true);
        })
    }

    async addBtAddresses(devicesListedCallback, finishCallback) {
        $("#scanners").html('');
        $('#duplicated-devices-message').hide();

        let currentAddresses = $(bulkElements.txtDevicesInfo).val().split('\n');
        $(bulkElements.txtDevicesInfo).val("");
        $(bulkElements.btnFinishDeviceInput).attr('disabled', true);          

        this.initOrCleanDeviceCollections();
        for (let address of currentAddresses) {
            let currentAddress = address.trim();

            if (!currentAddress) {
                continue;
            }

            if (this.addresses.includes(currentAddress)) {
                this.duplicatedAddresses.push(currentAddress);
                continue;
            }

            this.addOrReplaceScanner(currentAddress);
        }

        this.updateScannerSummary();
        devicesListedCallback();

        let allDevicePromises = [];
        for(let address of this.addresses) {
            let promise = this.tryGetScanner(address)
                .then((scanner) => {
                    this.verifyScanner(address, scanner);
                })
                .catch((err) => {
                    this.displayScannerError(address);
                });
            allDevicePromises.push(promise);
        }

        await Promise.allSettled(allDevicePromises)
            .then(() => {
                this.checkForDuplicatedDevices();
                finishCallback();
            });
    }

    checkForDuplicatedDevices(){
        let duplicatedDevicesMessage = $('#duplicated-devices-message');
        let duplicatedDevicesList = $('#duplicated-devices-list');

        duplicatedDevicesList.empty();

        if (duplicatedDevicesMessage && this.duplicatedAddresses && this.duplicatedAddresses.length > 0) {
            for(let address of this.duplicatedAddresses){
                duplicatedDevicesList.append(`${address}`);
            }
            $('#duplicated-devices-message').show();
        }
    }

    showTryAgainErrorMessage(error) {
        $('#tryAgainErrorMessage').html(error);
        $('#tryAgainErrorMessage').show();
        $('#tryAgainHelp').show();
        $('#tryAgainDoneButton').hide();
    }

    retryVerifyDevice(){
        let address = $('#tryAgainBluetoothAddress').val();
        let originalAddress = $('#tryAgainBluetoothAddress').data("original-address");
    
        if (address != originalAddress && this.addresses.includes(address)) {
            this.showTryAgainErrorMessage('This device is already included!')
            return;
        }
    
        this.tryGetScanner(address)
            .then((scanner) => {
                if (!scanner?.Warranty?.Description || !scanner?.Warranty?.EndDate) {
                    this.showTryAgainErrorMessage("An error occured, please try again!");
                    return;
                }
    
                this.notRecognizedAddresses = this.notRecognizedAddresses.filter(x => x != originalAddress);
                this.addOrReplaceScanner(address, originalAddress);
                this.verifyScanner(address, scanner);
    
                $('#tryAgainSuccessMessage').html("The device was recognized ...");
                $('#tryAgainSuccessMessage').show();
                $('#tryAgainErrorMessage').hide();
                $('#tryAgainHelp').hide();
                $('#tryAgainDoneButton').show();
            })
            .catch((err) => {
                this.showTryAgainErrorMessage(err)
            });
    }
    
    addOrReplaceScanner(address, addressToReplace) {
        if (addressToReplace) {
            this.addresses = this.addresses.filter(x => x != addressToReplace);
        }
        this.addresses.push(address);
    
        let template = $('#scanner-entry').html();
        let templateScript = Handlebars.compile(template);
        let html = templateScript({ address });
        let htmlObj = $(html)
        htmlObj.find(".js-scanner-refresh").on("click", () => {
            $('#tryAgainBluetoothAddress').val(address);
            $('#tryAgainBluetoothAddress').data("original-address", address);
            $("#bluetoothAddressModal").modal("show");
    
            $('#tryAgainErrorMessage').html('');
            $('#tryAgainErrorMessage').hide();
            $('#tryAgainSuccessMessage').html('');
            $('#tryAgainSuccessMessage').hide();
            $('#tryAgainHelp').show();
            $('#tryAgainDoneButton').hide();
        });
    
        if (addressToReplace) {
            $(`#scanner-${addressToReplace}`).replaceWith(htmlObj);
        }
        else {
            $("#scanners").append(htmlObj);
        }
    
        $(".scanners-list-wrapper").css("display", "block");
    }

    removeScannerEntry(address){
        this.addresses = this.addresses.filter(x => x != address);

        $(`#scanner-${address}`).remove();

        this.updateScannerSummary();
    }
        
    updateScannerSummary() {
        let verifiedDevicesCount = this.premiumScanners.length
            + this.accidentalScanners.length
            + this.standardScanners.length
            + this.outOfWarrantyScanners.length;

        let deviceCounts = {
            verified: verifiedDevicesCount,
            total: this.addresses.length + this.duplicatedAddresses.length,
            premium: this.premiumScanners.length,
            accidental: this.accidentalScanners.length,
            standard: this.standardScanners.length,
            outOfWarranty: this.outOfWarrantyScanners.length,
            notRecognized: this.notRecognizedAddresses.length,
            skipped: this.duplicatedAddresses.length
        };

        let template = $('#scanner-summary-template').html();
        let templateScript = Handlebars.compile(template);
        let html = templateScript(deviceCounts);

        $(document.getElementById('scanner-summary')).html(html);
    }

    isValidBluetoothAddress(address) {
        return address.match(/^[0-9a-fA-F]{12}\b$/);
    }

    isValidSerialNumber(number) {
        return number.match(/^[0-9]{17}\b$/);
    }

    isAddressAlreadyAdded(address) {
        return this.premiumScanners.filter(s => s.Product.MacAddress == address).length
            || this.accidentalScanners.filter(s => s.Product.MacAddress == address).length
            || this.standardScanners.filter(s => s.Product.MacAddress == address).length
            || this.outOfWarrantyScanners.filter(s => s.Product.MacAddress == address).length;
    }

    tryGetScanner(address) {
        let promise = new Promise((resolve, reject) => {
            if (!this.isValidBluetoothAddress(address) && !this.isValidSerialNumber(address)) {
                reject(window.batchResources.validation.invalidDeviceId);
                return;
            }
            if (this.isAddressAlreadyAdded(address)) {
                reject(window.batchResources.validation.addressAlreadyAdded);
                return;
            }

            let endpoint;
            if (this.isValidSerialNumber(address)) {
                endpoint = scannersUrl.concat("/", address);
            }
            else if(this.isValidBluetoothAddress(address)) {
                endpoint = scannersUrl.concat("/", address);
            }

            $.ajax({
                type: "GET",
                url: endpoint,
                contentType: "application/json",
                headers: {
                    "Authorization": scannersAuth,
                    "x-functions-key": scannersKey,
                },
                success: (scanner) => {
                    resolve(scanner);
                },
                error: (err) => {
                    displayScannerError(address, null, "unverified");
                    reject(JSON.parse(err.responseText).ErrorMessage);
                }
            });
        });

        return promise;
    }

    displayScannerError(address) {
        this.notRecognizedAddresses.push(address);
    
        let template = $('#scanner-not-recognized-status').html();
        let templateScript = Handlebars.compile(template);
        let html = templateScript();
    
        $(document.getElementById(`scanner-${address}`)).find('.js-scanner-refresh')?.show();
        $(document.getElementById(`scanner-${address}-status`)).html(html);
    
        this.updateScannerSummary();
    }

    verifyScanner(address, scanner) {
        if (!scanner?.Warranty?.Description || !scanner?.Warranty?.EndDate) {
            this.displayScannerError(address);
            return;
        }

        let template;
        let targetBatch;
        if (!this.isWarrantyActive(scanner.Warranty.EndDate)) {
            template = $('#scanner-out-of-warranty-status').html();
            targetBatch = this.outOfWarrantyScanners;
        }
        else if (scanner.Warranty.Description.toLowerCase().includes("premium")) {
            template = $('#scanner-premium-support-status').html();
            targetBatch = this.premiumScanners;
        }
        else if (scanner.Warranty.Description.toLowerCase().includes("accidental")) {
            template = $('#scanner-accidental-support-status').html();
            targetBatch = this.accidentalScanners;
        }
        else {
            template = $('#scanner-standard-support-status').html();
            targetBatch = this.standardScanners;
        }

        // Check if the same device is already added
        // (if the same device is added once with bluetooth address and once again with serialNumber)
        let alreadyAddedScanners = targetBatch.filter(x => {return x.Product.MacAddress == scanner.Product.MacAddress});
        if (alreadyAddedScanners && alreadyAddedScanners.length > 0) {
            this.removeScannerEntry(address);
            this.duplicatedAddresses.push(address);
        }
        else {
            targetBatch.push(scanner);
        }

        let templateScript = Handlebars.compile(template);
        let html = templateScript(scanner);

        $(document.getElementById(`scanner-${address}-status`)).html(html);

        $(document.getElementById(`scanner-${address}-description`)).html("<p>" + scanner.Product.Description + "</p>");

        this.updateScannerSummary();
    }

    compareDates(dateTimeA, dateTimeB) {
        let momentA = moment(dateTimeA, 'YYYY-MM-DD');
        let momentB = moment(dateTimeB, 'YYYY-MM-DD');
        if (momentA > momentB) return 1;
        else if (momentA < momentB) return -1;
        else return 0;
    }
    
    isWarrantyActive(endDate) {
        if (this.compareDates(endDate, moment()) >= 0) {
            return true;
        } else {
            return false;
        }
    }

    generateBatches(){
        window.state.currentBatchIndex = 0;
        window.state.batches = [];

        if (this.premiumScanners.length > 0) {
            let premiumBatch = this.createBatch(this.premiumScanners, batchIds.premium, window.batchResources.batchLabels.premium)
            window.state.batches.push(premiumBatch);
        }
        if (this.accidentalScanners.length > 0) {
            let accidentalBatch = this.createBatch(this.accidentalScanners, batchIds.accidental, window.batchResources.batchLabels.accidental)
            window.state.batches.push(accidentalBatch);
        }
        if (this.standardScanners.length > 0) {
            let standardBatch = this.createBatch(this.standardScanners, batchIds.standard, window.batchResources.batchLabels.standard)
            window.state.batches.push(standardBatch);
        }
        if (this.outOfWarrantyScanners.length > 0) {
            let outOfWarrantyBatch = this.createBatch(this.outOfWarrantyScanners, batchIds.outOfWarranty, window.batchResources.batchLabels.outOfWarranty)
            window.state.batches.push(outOfWarrantyBatch);
        }
        if (this.notRecognizedAddresses.length > 0) {
            let notRecognizedBatch = {
                batchId: batchIds.notRecognized,
                label: window.batchResources.batchLabels.notRecognized,
                originalItems: this.notRecognizedAddresses
            };
    
            window.state.batches.push(notRecognizedBatch);
        }
    }

    createBatch(scanners, batchId, warrantyLabel) {
        scanners.forEach((scanner) => {
            scanner.isReasonSet = false;
            scanner.problemText = '';
            scanner.warrantyClass = batchId;
            scanner.warrantyLabel = warrantyLabel;
        });
    
        let dataSource = new kendo.data.DataSource({
            data: scanners
        });

        let batch = {
            batchId: batchId,
            originalItems: scanners,
            label: warrantyLabel,
            dataSource: dataSource
        };
    
        return batch;
    }
}