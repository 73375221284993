export const screenIds = {
    error: 'error',
    deviceInput: 'device-input',
    recognizedDevices: 'recognized-devices',
    identifyProblems: 'identify-problems',
    replacementRequest: 'replacement-request',
    outOfWarrantyEnquiry: 'out-of-warranty-enquiry',
    reviewUnrecognizedDevices: 'review-unrecognized-devices',
    shippingInformation: 'shipping-information',
    paymentInformation: 'payment-information',
    replacementSummary: 'replacement-summary',
    submitConfirmation: 'submit-confirmation'
};

export function showScreen(screenId) {
    $(`[id^=screen-id-]`).hide();
    $(`#screen-id-${screenId}`).show();
}