import axios from "axios";
import { userLocationServiceUrl, storeLocationServiceUrl, shippingInfoServiceUrl, depositInfoServiceUrl } from "../../config";

export default class ServiceOptions {
    constructor() {
        this.result = {};
    }

    async getUserLocation() {
        try {
            const response = await axios.get(userLocationServiceUrl);

            let ipAddress = response.data.ip;
            return ipAddress;
        }
        catch (error) {
            throw Error(error);
        }
    }

    async getSocketStoreLocation() {
        try {
            let userIP = await this.getUserLocation();
            let storeLocationServiceEndpoint = storeLocationServiceUrl.concat("/",
                "?ipaddress=",
                userIP
            );

            const response = await axios.get(storeLocationServiceEndpoint);

            let location = response.data;

            const storeLocation = {
                region: location.region,
                country: location.country,
                language: "EN",
            };
            this.result.storeLocation = JSON.stringify(storeLocation);
        }
        catch (error) {
            throw Error(error);
        }
    }

    async getSocketShippingOptions() {
        try {
            let storeLocation = JSON.parse(window.state.serviceOptionsModel.result.storeLocation);
            let country = storeLocation?.country;
            let language = storeLocation?.language;

            if (country != undefined && language != undefined) {
                let shippingInfoServiceEndpoint = shippingInfoServiceUrl.concat("/",
                    "?country=",
                    country,
                    "&language=",
                    language
                );

                const response = await axios.get(shippingInfoServiceEndpoint);

                this.result.serviceData = response.data;
            }
        } catch (error) {
            throw Error(error);
        }
    }

    async getSingleDeviceDepositPromise(currency, serialNumber) {
        try {
            let depositInfoServiceEndpoint = depositInfoServiceUrl.concat("/",
                "?serialnumber=",
                serialNumber,
                "&currency=",
                currency
            );

            const response = await axios.get(depositInfoServiceEndpoint);

            window.state.batches[0].originalItems.forEach(device => {
                if (device.Product.SerialNumber === serialNumber) {
                    device.Product.DeviceDeposit = response.data.amount;
                }
            });

            return response.data.amount;
        }
        catch (error) {
            throw Error(error);
        }
    }

    async getMultipleDevicesDeposit(currency, serialNumbers) {
        try {
            if (serialNumbers && serialNumbers.length > 0) {
                const promises = [];

                for (let i = 0; i < serialNumbers.length; i++) {
                    promises.push(this.getSingleDeviceDepositPromise(currency, serialNumbers[i]));
                }

                let result = await Promise.all(promises)
                    .then(results => {
                        let sum = results.reduce((a, b) => Number(a) + Number(b), 0);

                        let deposits = {
                            totalSum: sum,
                            individualDeposits: results
                        }

                        return deposits;
                    })
                    .catch(error => {
                        throw Error(error);
                    });

                return result;
            }

        }
        catch (error) {
            throw Error(error);
        }
    }
}

