export const elements = {
    loader: document.querySelector('#loader'),
    bluetoothAddressInput: document.querySelector('#bluetoothAddress'),
    serialNumberInput: document.querySelector('#serialNumber'),
    form: '.replacement-form',
    containerForDeviceInfo: document.querySelector('.form-replacement-confirm .form-row.form-info'),
    scannerNotFoundModal: document.querySelector('#scannerNotFoundModal'),
    alert: document.querySelector('.alert'),
    alertErrorMessage: document.querySelector('.alert .error-message'),
    nextBtn: '.btn-next',
    cardElement: '#card-element',
    cardError: '#card-error',
    depositCheckboxWrapper: '#deposit-wrapper',
    processingSelectWrapper: '#processing-select-wrapper',
    billingAddressWrapper: '#billing-address-wrapper',
    billingAddressSameAsShippingCheckbox: '#billingAddressSameAsShippingChk',
    serviceDetailsWrapper: '.service-details-wrapper',
    exclusiveOffersWrapper: '#exclusive-offers-wrapper',
    upgradeLink: '#upgrade-link',
    discountLink: '#discount-link'
};

let servicesBaseUrl = $(elements.form).attr("data-services-base-url")?.trim();

if (servicesBaseUrl?.substr(-1) != '/') {
    servicesBaseUrl += '/';
}

export const currentCulture = $(elements.form).attr("data-current-culture");
export const scannersUrl = $(elements.form).attr("data-scanners-url");
export const scannersKey = $(elements.form).attr("data-scanners-key");
export const scannersAuth = $(elements.form).attr("data-scanners-auth");

let serialnumbersConfiguration = $(elements.form).attr("data-serial-numbers-url");
export const serialnumbersUrl = serialnumbersConfiguration ? serialnumbersConfiguration : "https://scannerinfofunction.azurewebsites.net/scanners";

export const problemsUrl = servicesBaseUrl?.concat($(elements.form).attr("data-problems-url")?.trim());
export const replacementUrl = servicesBaseUrl?.concat($(elements.form).attr("data-replacement-url")?.trim());
export const incidentsUrl = servicesBaseUrl?.concat($(elements.form).attr("data-incidents-url")?.trim());
export const countriesUrl = servicesBaseUrl?.concat($(elements.form).attr("data-countries-url")?.trim());
export const storeLocationServiceUrl = servicesBaseUrl?.concat($(elements.form).attr("data-store-location-url")?.trim());
export const shippingInfoServiceUrl = servicesBaseUrl?.concat($(elements.form).attr("data-shipping-url")?.trim());
export const depositInfoServiceUrl = servicesBaseUrl?.concat($(elements.form).attr("data-deposits-url")?.trim());
export const stripePaymentIntentServiceUrl = '/api/stripepaymentintent/';
export const userLocationServiceUrl = 'https://api.ipify.org?format=json';
export const userName = '21EC2020-3AEA-4069-A2DD-08002B30309D';
export const password = 'com.socketmobile.RmaSwapApp';
export const authToken = btoa(''.concat(userName, ':').concat(password));