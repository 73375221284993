import { screenIds } from "./batchReplacement/navigation";

export const bulkElements = {
    txtDevicesInfo: document.querySelector('#txtDevicesInfo'),
    btnBackAll: document.querySelectorAll(".js-btn-back"),
    btnTryAgainVerifyDevice: document.querySelector("#btnTryAgainVerifyDevice"),

    btnFinishDeviceInput: document.querySelector('#btn-finish-device-input'),
    btnFinishRecognizedDevices: document.querySelector("#btn-finish-recognized-devices"),
    btnFinishProblemIdentification: document.querySelector('#finish-problem-identification'),
    btnFinishReplacementRequest: document.querySelector('#finish-replacement-request'),
    btnFinishOutOfWarrantyEnquiry: document.querySelector('#finish-out-of-warranty-enquiry'),
    btnFinishReviewUnrecognizedDevices: document.querySelector('#finish-review-unrecognized-devices'),         
    btnFinishShippingInformation: document.querySelector('#finish-shipping-information'),
    btnFinishPaymentInformation: document.querySelector('#finish-payment-information'),
    btnFinishReplacementSummary: document.querySelectorAll('.js-finish-replacement-summary'),

    formOutOfWarrantyEnquiry: document.querySelector(`#screen-id-${screenIds.outOfWarrantyEnquiry}`),
    formReplacementRequest: document.querySelector(`#screen-id-${screenIds.replacementRequest}`),
    formShippingInformation: document.querySelector(`#screen-id-${screenIds.shippingInformation}`),
    formPaymentInformation: document.querySelector(`#screen-id-${screenIds.paymentInformation}`),

    formProblemIdentificationModal: document.querySelector("#form-problem-identification-modal"),

    modalProblemIdentification: document.querySelector("#problemIdentificationModal"),

    incompleteProblemsListMessage: document.querySelector("#incomplete-problems-list-message"),
};

export const batchIds = {
    premium: 'premium',
    accidental: 'accidental',
    standard: 'standard',
    outOfWarranty: 'warranty',
    notRecognized: 'notrecog'
};
