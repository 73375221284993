import ScannerIdentification from "./batchReplacement/ScannerIdentification";
import ProblemIdentification from "./batchReplacement/ProblemIdentification";

import { submitReplacements } from "./batchReplacement/models/replacementRequest.js";
import { initReplacementRequestView, loadReplacementRequestView, updateReplacementRequestNextButton } from "./batchReplacement/views/ReplacementRequestView";
import { initOutOfWarrantyEnquiry, saveOutOfWarrantyEnquiry } from "./batchReplacement/outOfWarrantyEnquiry";
import { bulkElements, batchIds } from "./configBatch";
import { showScreen, screenIds } from "./batchReplacement/navigation";

import ServiceOrder from "./batchReplacement/models/ServiceOrder";
import { validateForm, showError } from "./batchReplacement/base-batch";
import * as countriesAndProblemsView from "./batchReplacement/views/countriesAndProblemsView";

// Imports, reused from single replacement
import CountriesAndProblems from "./models/CountriesAndProblems";
import {
    OffCanvasToggle,
    EqualWidthButtons,
    showLoader,
    removeLoader
} from "./base";
import { elements } from "./config";


/*Global state for the entire app
    * Keeps Models instances and passes axios results to Views
    */
window.state = {
    currentBatchIndex: 0,
    batches: []
};

// Utilities
async function navigateBack(e) {
    try {
        let button = e.target.closest('.js-btn-back');

        let screenId;
        if (button && button.getAttribute('data-screen-id')) {
            screenId = button.getAttribute('data-screen-id');
        }

        switch (screenId) {
            case screenIds.outOfWarrantyEnquiry:
            case screenIds.identifyProblems:
                if (window.state.currentBatchIndex === 0) {
                    showScreen(screenIds.recognizedDevices);
                } else {
                    window.state.currentBatchIndex--;
                    updateBatchHeader();
                    await loadReplacementRequestView();
                    showScreen(screenIds.replacementRequest);
                }
                break;
            case screenIds.replacementRequest:
                loadProblemIdentification();
                break;
            case screenIds.reviewUnrecognizedDevices:
                if (window.state.currentBatchIndex === 0) {
                    showScreen(screenIds.recognizedDevices);
                } else {
                    window.state.currentBatchIndex--;
                    updateBatchHeader();

                    if(window.state.batches[window.state.currentBatchIndex].batchId === batchIds.outOfWarranty) {
                        await reviewOutOfWarrantyDevices();
                    }
                    else {
                        await loadReplacementRequestView();
                        showScreen(screenIds.replacementRequest);
                    }
                }
                break;
            case screenIds.shippingInformation:
                let currentBatchId = window.state.batches[window.state.currentBatchIndex].batchId;

                if (currentBatchId === batchIds.notRecognized) {
                    showScreen(screenIds.reviewUnrecognizedDevices);
                } else if (currentBatchId === batchIds.outOfWarranty) {
                    await reviewOutOfWarrantyDevices();
                } else { // premium, accidental or standard
                    loadReplacementRequestView();
                    showScreen(screenIds.replacementRequest);
                }
                break;
            case screenIds.paymentInformation:
                showScreen(screenIds.shippingInformation);
                break;
            case screenIds.replacementSummary:
                if (window.state.hasPayments) {
                    showScreen(screenIds.paymentInformation);
                }
                else {
                    showScreen(screenIds.shippingInformation);
                }
                break;
            case screenIds.recognizedDevices:
            default:
                window.state.currentBatchIndex = 0;
                window.state.batches = [];
                showScreen(screenIds.deviceInput);
                break;
        }
    } catch (e) {
        showError();
    }
}

function updateBatchHeader(decrementItems){
    let headingElements = $('.js-batch-heading');

    let deviceCount = 0;
    let currentBatch = window.state.batches[window.state.currentBatchIndex];

    if (currentBatch?.batchId === batchIds.notRecognized) {
        deviceCount = currentBatch.originalItems?.length;
    } else {
        deviceCount = currentBatch?.dataSource?.total();
    }

    if (decrementItems) {
        deviceCount--;
    }

    let deviceCountLabel = deviceCount === 1 ? `1 ${window.batchResources.deviceSingular}` : `${deviceCount} ${window.batchResources.devicesPlural}`;
    
    let headingHtml = `${window.batchResources.batch} ${window.state.currentBatchIndex + 1}: <span>${window.state.batches[window.state.currentBatchIndex].label} - ${deviceCountLabel}</span>`;

    headingElements.html(headingHtml);
}

function initBatchRma() {
    try {
        // Init main views
        window.state.scannerIdentification = new ScannerIdentification();
        window.state.scannerIdentification.init();

        window.state.problemIdentification = new ProblemIdentification();
        window.state.problemIdentification.init();

        initReplacementRequestView();
        initOutOfWarrantyEnquiry();

        // All back buttons
        $(bulkElements.btnBackAll).click(navigateBack);

        // Next buttons per screen
        $(bulkElements.btnFinishDeviceInput).click(finishDeviceInput);
        $(bulkElements.btnFinishRecognizedDevices).click(finishRecognizeDevices);
        $(bulkElements.btnFinishProblemIdentification).click(finishProblemIdentification);
        $(bulkElements.btnFinishReplacementRequest).click(finishReplacementRequest);
        $(bulkElements.btnFinishOutOfWarrantyEnquiry).click(finishOutOfWarrantyEnquiry);
        $(bulkElements.btnFinishReviewUnrecognizedDevices).click(finishReviewUnrecognizedDevices);
        $(bulkElements.btnFinishShippingInformation).click(finishShippingInformation);
        $(bulkElements.btnFinishPaymentInformation).click(finishPaymentInformation);
        $(bulkElements.btnFinishReplacementSummary).click(finishReplacementSummary);
    } catch (error) {
        showError();
    }
}

async function finishDeviceInput() {
    try {
        if ($(this).is("[disabled]")) {
            event.preventDefault();
        }

        $(bulkElements.btnFinishRecognizedDevices).attr('disabled', true);

        let enableNextButtonCallback = (results) => {
            $(bulkElements.btnFinishRecognizedDevices).attr('disabled', false);
        }

        // In this case we don't need to show a loading indicator as each device on the recognizedDevices screen has one.
        // The next screen will be loaded once all devices have been locally listed, but without waiting for all calls to the service to finish
        let devicesListedCallback = () => {
            showScreen(screenIds.recognizedDevices);
        };

        await window.state.scannerIdentification.addBtAddresses(devicesListedCallback, enableNextButtonCallback);
    } catch (error) {
        showError();
    }
}

async function finishRecognizeDevices(){
    try {
        window.state.scannerIdentification.generateBatches();

        if (window.state.batches.length > 0) {
            window.state.currentBatchIndex = 0;

            updateBatchHeader();

            if (window.state.batches[0].batchId === batchIds.notRecognized) {
                loadUnrecognizedDevices();
            }
            else if (window.state.batches[0].batchId === batchIds.outOfWarranty) {
                await reviewOutOfWarrantyDevices();
            }
            else {
                loadProblemIdentification();
            }
        }
        else{
            showError();
        }
    } catch (error) {
        showError();
    }
}

async function loadProblemIdentification(){
    try {
        const currentBatch = window.state.batches[window.state.currentBatchIndex];

        showLoader();

        await window.state.problemIdentification.loadProblemsOptions();

        $('#problem-identification-list').kendoListView({
            dataSource: currentBatch.dataSource,
            pageable: false,
            scrollable: true,
            template: kendo.template($("#scanner-entry-problem-identification").html()),
            remove: function(e) { updateBatchHeader(true) }
        });

        updateBatchHeader();

        window.state.problemIdentification.checkForUnpopulatedProblems(false);

        showScreen(screenIds.identifyProblems);
    } catch (error) {
        showError();
    }
    finally {
        removeLoader();
    }
}

async function checkIfAllBatchDevicesAreDeleted() {
    const currentBatch = window.state.batches[window.state.currentBatchIndex];

    if (!currentBatch.dataSource.total()) {
        // All devices have been deleted from the batch so we are removing the empty batch
        window.state.batches.splice(window.state.currentBatchIndex, 1);
        window.state.currentBatchIndex--;

        //Skipping the next steps for this batch and jumping to the next batch
        await loadNextBatchOrStep();
        return true;
    }

    return false;
}

async function finishProblemIdentification() {
    try {
        let allDeviceProblemsArePopulated = window.state.problemIdentification.checkForUnpopulatedProblems(true);

        if (!allDeviceProblemsArePopulated) return;
        
        showLoader();

        if (await checkIfAllBatchDevicesAreDeleted()) {
            return;
        }

        await loadReplacementRequestView();

        updateReplacementRequestNextButton();

        showScreen(screenIds.replacementRequest);
    } catch (error) {
        showError();
    }
    finally {
        removeLoader();
    }
}

async function finishReplacementRequest() {
    try {
        validateForm($(bulkElements.formReplacementRequest));

        if ($(bulkElements.formReplacementRequest).valid() == false) {
            return;
        }

        let currentBatch = window.state.batches[window.state.currentBatchIndex];

        currentBatch.selectedServiceOption = window.state.selectedServiceOption;
        currentBatch.plan = window.state.selectedPlan;

        window.state.hasInWarrantyDevices = true;
        if (currentBatch.selectedServiceOption.cost || currentBatch.selectedServiceOption.deposit) {
            window.state.hasPayments = true;
        }

        await loadNextBatchOrStep();
    } catch (error) {
        showError();
    }
}

async function loadNextBatchOrStep() {
    try {
        if (window.state.currentBatchIndex < window.state.batches.length - 1) {
            window.state.currentBatchIndex++;

            let newBatch = window.state.batches[window.state.currentBatchIndex];

            if (newBatch.batchId === batchIds.standard ||
                newBatch.batchId === batchIds.accidental) {
                loadProblemIdentification();
            }
            else if(newBatch.batchId === batchIds.outOfWarranty) {
                await reviewOutOfWarrantyDevices();
            }
            else {
                updateBatchHeader();
                loadUnrecognizedDevices();
            }
        } else if (window.state.currentBatchIndex === -1) {
            // All batches have been deleted by the user during the process. Cancell the process.
            showError(window.batchResources.allDevicesRemovedMessage);
        }
        else{
            showShippingInformationView();
        }
    } catch (error) {
        showError();
    }
}

async function finishOutOfWarrantyEnquiry(){
    try {
        if (await checkIfAllBatchDevicesAreDeleted()) {
            return;
        }

        validateForm($(bulkElements.formOutOfWarrantyEnquiry));

        if ($(bulkElements.formOutOfWarrantyEnquiry).valid() == false) {
            return false;
        }

        saveOutOfWarrantyEnquiry();

        if (window.state.currentBatchIndex < window.state.batches.length - 1) {
            window.state.currentBatchIndex++;
            updateBatchHeader();
            loadUnrecognizedDevices();
        }
        else{
            showShippingInformationView();
        }
    } catch (error) {
        showError();
    }
}

function finishReviewUnrecognizedDevices(){
    showShippingInformationView();
}

async function finishShippingInformation(){
    try {
        validateForm($(bulkElements.formShippingInformation));

        if ($(bulkElements.formShippingInformation).valid() == false) {
            return false;
        }

        // Store the shipping info content
        let serializedObject = $(bulkElements.formShippingInformation)?.serializeJSON();
        sessionStorage.setItem("contactInformation", JSON.stringify(serializedObject));

        if (window.state.hasPayments) {
            await showPaymentInformationView();
        }
        else if (window.state.hasInWarrantyDevices) {
            showReplacementSummaryView();
        } else {
            submitAndShowConfirmation();
        }
    } catch (error) {
        showError();
    }
}

async function finishPaymentInformation(){
    try {
        validateForm($(bulkElements.formPaymentInformation));

        if ($(bulkElements.formPaymentInformation).valid() == false) {
            return false;
        }

        let billingAddressSameAsShipping = $(bulkElements.formPaymentInformation).find(elements.billingAddressSameAsShippingCheckbox).prop("checked");


        showLoader();

        let billingDetails;

        if (billingAddressSameAsShipping) {
            let contactInformation = JSON.parse(sessionStorage.getItem("contactInformation"));
            billingDetails = contactInformation;
        }
        else {
            let serializedObject = $(bulkElements.formPaymentInformation).serializeJSON();
            sessionStorage.setItem("billingAddress", JSON.stringify(serializedObject));
            billingDetails = serializedObject;
        }

        try {
            await window.state.serviceOrderModel.createPaymentMethod(window.state.CardElement, billingDetails);

            showReplacementSummaryView();
        } catch (error) {
            $(bulkElements.btnFinishPaymentInformation).attr('disabled', true);
            $(elements.cardError).text(error);
        }
    } catch (error) {
        showError();
    }
    finally {
        removeLoader();
    }
}

function finishReplacementSummary(){
    submitAndShowConfirmation();
}

// Init and load next screen functions
async function reviewOutOfWarrantyDevices() {
    try {
        showLoader();

        await window.state.problemIdentification.loadProblemsOptions();

        $('#scanner-review-list').kendoListView({
            dataSource: window.state.batches[window.state.currentBatchIndex].dataSource,
            pageable: false,
            scrollable: true,
            template: kendo.template($("#scanner-entry-out-of-warranty").html()),
            remove: function(e) { updateBatchHeader(true) }
        });

        updateBatchHeader();

        showScreen(screenIds.outOfWarrantyEnquiry);
    } catch (error) {
        showError();
    } finally {
        removeLoader();
    }
}

function loadUnrecognizedDevices(){
    try {
        let template = $('#unrecognized-scanner-entry').html();
        let templateScript = Handlebars.compile(template);

        const unrecognizedScanners = window.state.batches[window.state.currentBatchIndex]?.originalItems;        

        if (!unrecognizedScanners) {
            showError();
        }

        $("#unrecognized-scanners").empty();
        for (let i = 0; i < unrecognizedScanners.length; i++) {

            let address = unrecognizedScanners[i];

            let html = templateScript({ address });
            let htmlObj = $(html);
       
            $("#unrecognized-scanners").append(htmlObj);
        }

        showScreen(screenIds.reviewUnrecognizedDevices);
    } catch (error) {
        showError();
    }
}

function showShippingInformationView(){
    try {
        showCountriesOptions(`#screen-id-${screenIds.shippingInformation}`);

        let buttonLabel = '';
        let screenTitle = window.batchResources.shippingInformationLabel;

        if (window.state.hasPayments) {
            buttonLabel = window.batchResources.continueToPaymentLabel;
            toggleShippingFields(true);
        }
        else if (window.state.hasInWarrantyDevices) {
            buttonLabel = window.batchResources.reviewReplacementLabel;
            toggleShippingFields(true);
        } else {
            buttonLabel = window.batchResources.sendEnquiryLabel;
            screenTitle = window.batchResources.contactInformationLabel;
            toggleShippingFields(true);
        }

        $(bulkElements.btnFinishShippingInformation).prop("value", buttonLabel);
        $(`#screen-id-${screenIds.shippingInformation} .js-shipping-info-title`).text(screenTitle);

        showScreen(screenIds.shippingInformation);
    } catch (error) {
        showError();
    }
}

function toggleShippingFields(shouldBeVisible) {
    if (shouldBeVisible) {
        $('.js-shipping-only').show();
    } else {
        $('.js-shipping-only').hide();
    }
}

async function showPaymentInformationView(){
    try {
        showLoader();

        //Populate Countries select
        showCountriesOptions(`#screen-id-${screenIds.paymentInformation}`);

        //Initialize Stripe
        let stripePublishableKey = $(elements.form).attr("data-stripe-pkey");
        window.state.serviceOrderModel = new ServiceOrder(Stripe(stripePublishableKey));

        //Billing address same as Shipping checkbox
        $(bulkElements.formPaymentInformation).find(elements.billingAddressSameAsShippingCheckbox).change(function (event) {
            if ($(event.target).prop("checked")) {
                $(elements.billingAddressWrapper).hide();
            } else {
                $(elements.billingAddressWrapper).show();
            }
        });

        await window.state.serviceOrderModel.createPaymentIntent();

        showScreen(screenIds.paymentInformation);
    } catch (error) {
        showError();
    }
    finally {
        removeLoader();
    }
}

function showReplacementSummaryView(){
    try {
        let contactInformation = JSON.parse(sessionStorage.getItem("contactInformation"));
        contactInformation.customer.fullName = `${contactInformation.customer.firstName} ${contactInformation.customer.lastName}`;

        let billingAddress = JSON.parse(sessionStorage.getItem("billingAddress"));

        let warrantyBatches = [];
        let totalDeviceCount = 0;
        let currency;
        window.state.totalShippingPrice = 0;
        window.state.totalDeposit = 0;

        for (let i = 0; i < window.state.batches.length; i++) {
            let batch = window.state.batches[i];

            if (batch.batchId === batchIds.standard
                || batch.batchId === batchIds.accidental
                || batch.batchId === batchIds.premium) {
                let batchDeviceCount = batch.dataSource.total();
                totalDeviceCount += batchDeviceCount;

                window.state.totalShippingPrice += batch.selectedServiceOption.cost;
                window.state.totalDeposit += batch.selectedServiceOption.deposit;

                currency = currency ?? batch.selectedServiceOption.currency;

                batch.batchNumber = i + 1;

                batch.deviceCountLabel = batchDeviceCount == 1 ? `1 ${window.batchResources.deviceSingular}` : `${batchDeviceCount} ${window.batchResources.devicesPlural}`;
                batch.selectedServiceOption.totalCost = batch.selectedServiceOption.cost + batch.selectedServiceOption.deposit;
                batch.devices = batch.dataSource.data();
                warrantyBatches.push(batch);
            }
        }

        let summary = {
            totalDeviceCount: totalDeviceCount,
            totalShippingPrice: window.state.totalShippingPrice,
            totalDeposit: window.state.totalDeposit,
            currency: currency,
            total: window.state.totalShippingPrice + window.state.totalDeposit,
            shippingAddress: contactInformation,
            billingAddress: billingAddress ? billingAddress : contactInformation,
            batches: warrantyBatches
        }

        let template = $('#replacement-summary-template').html();
        let templateScript = Handlebars.compile(template);
        let html = templateScript(summary);
        let htmlObj = $(html)

        htmlObj.find(".expander__toggle").on("click", toggleBatchSummaryExpander);

        $(document.getElementById('replacement-summary-container')).html(htmlObj);

        $('input.js-finish-replacement-summary').prop("disabled", true);

        $('input[type=checkbox]#accept-no-accessories').change(
            function () {
                if (this.checked) {
                    $('input.js-finish-replacement-summary').prop("disabled", false);
                } else {
                    $('input.js-finish-replacement-summary').prop("disabled", true);
                }
            }
        );

        showScreen(screenIds.replacementSummary);
    } catch (error) {
        showError();
    }
    finally {
        removeLoader();
    }
}

async function submitAndShowConfirmation(){
    try {
        showLoader();

        let submitResult = await submitReplacements();
        let incidentsResult = submitResult?.incidents;
        let replacementsResult = submitResult?.replacements;

        let incidentNumbersDisplayMessage = '';

        let hasPayments =  window.state.totalShippingPrice || window.state.totalDeposit;

        // Payment
        if (incidentsResult?.isExecuted) {
            if (!incidentsResult?.isSuccessful || !incidentsResult?.incidentNumbers?.length) {
                showError();
                return;
            }

            incidentNumbersDisplayMessage = getIncidentNumbersDisplayMessage(incidentsResult?.incidentNumbers)

            if (hasPayments) {
                try {
                    let incidentNumbersList = incidentsResult.incidentNumbers.join(',');
                    await window.state.serviceOrderModel.confirmPayment(incidentNumbersList);
                } catch (ex) {
                    showError(window.batchResources.paymentErrorMessage, incidentNumbersDisplayMessage, window.batchResources.contactSupportMessage);
                    return;
                }
            }
        }

        let hasOutOfWarrantyDevices = replacementsResult?.outOfWarrantySerialNumbers?.length;
        let hasUnrecognizedDevices = replacementsResult?.unrecognizedNumbers?.length;

        let replacementsHeading;
        if (hasOutOfWarrantyDevices && hasUnrecognizedDevices) {
            replacementsHeading = window.batchResources.notRecognizedAndOutOfWarrantyLabel;
        }
        else if (hasOutOfWarrantyDevices) {
            replacementsHeading = window.batchResources.outOfWarrantyDevicesLabel;
        }
        else {
            replacementsHeading = window.batchResources.notRecognizedDevicesLabel;
        }

        let viewModel = {
            showIncidents: incidentsResult?.isExecuted,
            incidentNumbersDisplayMessage: incidentNumbersDisplayMessage,
            hasMultipleIncidents: incidentsResult?.incidentNumbers?.length && incidentsResult?.incidentNumbers?.length > 1,
            showReplacements: replacementsResult?.isExecuted,
            replacementsHeading: replacementsHeading,
            areReplacementsSuccessful: replacementsResult?.isSuccessful,
            outOfWarrantySerialNumbers: replacementsResult?.outOfWarrantySerialNumbers,
            unrecognizedNumbers: replacementsResult?.unrecognizedNumbers
        };

        showConfirmationView(viewModel);

    } catch (error) {
        showError();
    } finally {
        removeLoader();
    }
}

function getIncidentNumbersDisplayMessage(incidentNumbers) {
    if (!incidentNumbers?.length) return '';

    if (incidentNumbers.length === 1) {
        return `${window.batchResources.yourRequestNumberSingular} ${incidentNumbers[0]}`;
    }

    try {
        splitNumbers = incidentNumbers.map(n => splitIncidentNumber(n));

        let sorted = splitNumbers.sort((a, b) => (a.trailingNumber > b.trailingNumber) ? 1 : -1);

        return `${window.batchResources.yourRequestNumbersPlural} ${sorted[0].originalNumber} to ${sorted[sorted.length - 1].originalNumber}`
    } catch (e) {
        return `${window.batchResources.yourRequestNumbersPlural} ${incidentNumbers[0]} to ${incidentNumbers[incidentNumbers.length - 1]}`
    }
}

function splitIncidentNumber(number) {
    let splitItems = number?.split('-');

    if (!splitItems?.length) return '';

    let trailingNumber = splitItems[splitItems.length - 1];
    let parsedTrailingNumber = parseInt(trailingNumber);

    return {
        originalNumber: number,
        trailingNumber:  parsedTrailingNumber ? parsedTrailingNumber : number
    };
}

function showConfirmationView(viewModel) {
    try {
        let template = $('#submit-confirmation-template').html();
        let templateScript = Handlebars.compile(template);
        let html = templateScript(viewModel);
        let htmlObj = $(html)

        $(document.getElementById('submit-confirmation-container')).html(htmlObj);

        showScreen(screenIds.submitConfirmation);
    } catch (error) {
        showError();
    }
}

function toggleBatchSummaryExpander(){
    this.classList.toggle("active");
    let panel = this.nextElementSibling;
        
    if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
    } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
    }
}

async function showCountriesOptions (parentContainerSelector) {
    try {
        let countriesAndProblems = new CountriesAndProblems();

        await countriesAndProblems.getCountries();

        countriesAndProblemsView.populateSelectWithCountries(
            countriesAndProblems.result.countries,
            parentContainerSelector
        );

        let countrySelector = `${parentContainerSelector} #country`;
        let stateSelector = `${parentContainerSelector} #state`;
        let stateLabelSelector = `${parentContainerSelector} label[for=state]`;

        $(countrySelector).change(function () {
            if (this.value !== 'US') {
                $(stateSelector).addClass('hidden');
                $(stateSelector).removeClass('required');
                $(stateSelector).prop("disabled", true);
                $(stateLabelSelector).addClass('hidden');
            } else {
                $(stateSelector).removeClass('hidden');
                $(stateSelector).addClass('required');
                $(stateSelector).prop("disabled", false);
                $(stateLabelSelector).removeClass('hidden');
            }
        }).change();
    } catch (error) {
        showError();
    }
    finally {
        removeLoader();
    }
};

/**
 *  Init validation and UI related JS
 */
OffCanvasToggle.init();
EqualWidthButtons.init();
initBatchRma();
