import axios from "axios";
import { incidentsUrl } from "../../config";
import { replacementUrl } from "../../config";
import { batchIds } from "../../configBatch";

export async function postRequest(requestUrl, data){
    try {
        const res = await axios.post(requestUrl, data);
        return res.data;
    } catch (error) {
        throw Error(error);
    }
}

// Incidents
function getInWarrantyDevicePayload(device, contactInformation, selectedPlan, selectedServiceOption) {
    let payload = {
        customer: contactInformation?.customer,
        problemDescription: device?.problemDescription,
        whatITried: device?.whatITried,
        problemCategory: device?.problemText,
        problemId: device?.problemId,

        deviceSerialNumber: device?.Product?.SerialNumber,
        deviceModel: device?.Product?.Description,

        warrantyDescription: device?.Warranty?.Description,
        warrantyEndDate: device?.Warranty?.EndDate,
        shippingId: selectedServiceOption?.id,

        repairPlan: {
            planname: selectedPlan?.replacementType,
            shippinglist: [
                {
                    id: selectedServiceOption?.id,
                    createdTime: selectedServiceOption?.createdTime,
                    shippingdescriptionlines: {
                        line1: selectedServiceOption?.line1,
                        line2: selectedServiceOption?.line2,
                        line3: selectedServiceOption?.line3
                    },
                    cost: selectedServiceOption?.cost,
                    status: selectedServiceOption?.status
                }
            ],
            deposit: selectedPlan?.deposit,
            depositAmount: device?.Product?.DeviceDeposit,
            currency: selectedPlan?.currency,
            expeditedShipping: selectedServiceOption?.expeditedShipping,
            language: selectedPlan?.language,
            region: selectedPlan?.region,
            regionCode: selectedPlan?.regionCode
        }
    };

    return payload;
}

// Replacements
function getOutOfWarrantyDevicePayload(device, contactInformation, outOfWarrantyEnquiry){
    let labels = window.batchResources.outOfWarrantyRequest;

    let message = `${labels.mainMessage} ${labels.serviceType}: ${outOfWarrantyEnquiry.serviceType}. `
        + `${labels.primaryIssue}: ${outOfWarrantyEnquiry.primaryIssue}. `
        + `${labels.symptom}: ${outOfWarrantyEnquiry.symptom}`;

     let payload = {
        subject: window.batchResources.outOfWarrantyRequest.subject,
        customer: contactInformation?.customer,
        deviceSerialNumber: device?.Product?.SerialNumber,
        warrantyDescription: device?.Warranty?.Description,
        warrantyEndDate: device?.Warranty?.EndDate,
        primaryIssue: outOfWarrantyEnquiry?.primaryIssue,
        symptom: outOfWarrantyEnquiry?.symptom,
        message: message
    }

    return payload;
}

function getIncidents(batch, contactInformation) {
    let data = batch?.dataSource?.data();
    if (!data) { return []; }

    data = addDepositAmount(data);

    let incidents = [];
    for (let i = 0; i < data.length; i++) {
        batch.plan.currency = batch.batchId == 'standard' ? null : batch.plan.currency;

        let device = getInWarrantyDevicePayload(data[i], contactInformation, batch.plan, batch.selectedServiceOption);
        incidents.push(device);
    }

    return incidents;
}

function getOutOfWarrantyReplacements(batch, contactInformation){
    let data = batch?.dataSource?.data();
    if (!data) { return []; }

    let replacements = [];
    for (let i = 0; i < data.length; i++) {
        let device = getOutOfWarrantyDevicePayload(data[i], contactInformation, batch?.outOfWarrantyEnquiry);
        replacements.push(device);
    }

    return replacements;
}

function addDepositAmount(data) {
    let tempBatch = window.state.batches[0];
    let depositMap = {};

    // Create a map for quick lookup
    for (let item of tempBatch.originalItems) {
        if (tempBatch.plan.currency != "JPY" || tempBatch.plan.currency != "jpy") {
            // If the currency is not Japanese Yen, convert the deposit to a float and format it to have two decimal places
            depositMap[item.Product.SerialNumber] = parseFloat(item.Product.DeviceDeposit).toFixed(2);
        } else {
            // If the currency is Japanese Yen, store the deposit as it is
            depositMap[item.Product.SerialNumber] = item.Product.DeviceDeposit;
        }

    }

    // Use the map to assign deposit amounts
    for (let item of data) {
        if (depositMap.hasOwnProperty(item.Product.SerialNumber)) {
            item.Product.DeviceDeposit = depositMap[item.Product.SerialNumber];
        }
    }

    return data;
}


function getUnrecognizedDevices(batch, contactInformation){
    let labels = window.batchResources.unrecognizedDevicesRequest;
    let result = [];

    if (batch.originalItems && batch.originalItems.length) {
        let idsList = batch?.originalItems?.join(', ');
        let payload = {
            customer: contactInformation.customer,
            message: `${labels.message} ${labels.deviceIds}: ${idsList}`,
            subject: labels.subject
        };

        result.push(payload);
    }

    return result;
}

function collectPayloads(){
    let result = {
        incidents: [],
        replacements: []
    };

    let contactInformation = JSON.parse(sessionStorage.getItem("contactInformation"));

    if (!contactInformation || !contactInformation.customer) {
        return result;
    }

    for (let i = 0; i < window.state.batches.length; i++) {
        let batch = window.state.batches[i];

        switch (batch.batchId) {
            case batchIds.standard:
            case batchIds.accidental:
            case batchIds.premium:
                result.incidents.push(...getIncidents(batch, contactInformation));
                break;
            case batchIds.outOfWarranty:
                result.replacements.push(...getOutOfWarrantyReplacements(batch, contactInformation));
                result.outOfWarrantySerialNumbers = result.replacements.map(x => x?.deviceSerialNumber);
                break;
            case batchIds.notRecognized:
            default:
                result.replacements.push(...getUnrecognizedDevices(batch, contactInformation));
                result.unrecognizedNumbers = batch.originalItems;
                break;
        }
    }

    return result;
}

async function executeIncidentsRequest(payload){
    let result = {};

    await postRequest(incidentsUrl, payload)
        .then(response => {
            result.isSuccessful = true;
            result.incidentNumbers = response.map(x => x?.incidentNumber);
        })
        .catch(error => {
            result.isSuccessful = false;
            result.error = error
        });

    return result;
}

async function executeReplacementsRequest(payload){
    let result = {};

    await postRequest(replacementUrl, payload)
        .then(response => {
            result.isSuccessful = true;
            result.replacementNumbers = response.map(x => x?.replacementNumber);
        })
        .catch(error => {
            result.isSuccessful = false;
            result.error = error
        });

    return result;
}

export const submitReplacements  = async () => {
    let result = {
        incidents: { },
        replacements: { }
    }

    let payloads = collectPayloads();

    if (payloads?.incidents?.length) {
        let incidentsResult = await executeIncidentsRequest(payloads.incidents);
        result.incidents.isExecuted = true;
        result.incidents.isSuccessful = incidentsResult.isSuccessful;
        result.incidents.incidentNumbers = incidentsResult.incidentNumbers;
    }

    if (payloads?.replacements?.length) {
        let replacementsResult = await executeReplacementsRequest(payloads.replacements);
        result.replacements.isExecuted = true;
        result.replacements.isSuccessful = replacementsResult.isSuccessful;
        result.replacements.replacementNumbers = replacementsResult.replacementNumbers;

        result.replacements.outOfWarrantySerialNumbers = payloads.outOfWarrantySerialNumbers;
        result.replacements.unrecognizedNumbers = payloads.unrecognizedNumbers;
    }

    return result;
}