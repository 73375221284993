import { bulkElements } from "../configBatch";
import { validateForm, clearFormErrors } from "./base-batch";
import CountriesAndProblems from "../models/CountriesAndProblems";
import * as countriesAndProblemsView from "./views/countriesAndProblemsView";

export default class ProblemIdentification {
    constructor(){
        this.countriesAndProblems = new CountriesAndProblems();
        this.initOrResetState();
    };

    initOrResetState() {
        this.currentSelectedItem = {};
        this.isInCommonMode = false;
        this.commonProblem = {};
    }

    init() {
        let container = document.getElementById('problem-identification-list');
        container.addEventListener('click', (e) => {this.editDeviceProblem(e)}, false)

        $('.js-save-problem').click(() => {this.saveProblem()});
        $('#btn-common-problem').click((e) => {this.addCommonProblem(e)});
    }

    async loadProblemsOptions() {
        this.initOrResetState();

        if (!this.countriesAndProblems.result || !this.countriesAndProblems.result.problems) {
            await this.countriesAndProblems.getProblems();

            countriesAndProblemsView.populateSelectWithActiveSymptoms(
                this.countriesAndProblems.result.problems
            );

            countriesAndProblemsView.populateAllProblemSelectors(
                this.countriesAndProblems.result.problems
            );
        }
    };
    
    editDeviceProblem(e) {
        let button = e.target.closest('.js-btn-edit-problem');
    
        if (button && button.getAttribute('data-device-id')) {
            let deviceId = button.getAttribute('data-device-id');
    
            this.currentSelectedItem = window.state.batches[window.state.currentBatchIndex].dataSource.data().filter(i => i.Product.MacAddress === deviceId)[0]
    
            if (this.currentSelectedItem) {
                $('#problemId').val(this.currentSelectedItem.problemId || '');
                $('#problemDescription').val(this.currentSelectedItem.problemDescription || '');
                $('#whatITried').val(this.currentSelectedItem.whatITried || '');
    
                this.isInCommonMode = false;
                clearFormErrors($(bulkElements.formProblemIdentificationModal));

                $('#problemIdentificationModalLabel').text(window.batchResources.deviceSpecificProblem);
                $('#commonProblemMessage').hide();

                $('#problemIdentificationDeviceId .js-number').text(this.currentSelectedItem.Product.MacAddress);
                $('#problemIdentificationDeviceId .js-model').text(this.currentSelectedItem.Product.Description);

                $('#problemIdentificationDeviceId').show();
                $(bulkElements.modalProblemIdentification).modal("show");
            }
        }
    }
    
    addCommonProblem(e) {
        e.preventDefault();
    
        $('#problemId').val(this.commonProblem?.problemId || '');
        $('#problemDescription').val(this.commonProblem?.problemDescription || '');
        $('#whatITried').val(this.commonProblem?.whatITried || '');
    
        this.isInCommonMode = true;
        clearFormErrors($(bulkElements.formProblemIdentificationModal));

        $('#problemIdentificationModalLabel').text(window.batchResources.allDevicesProblem);
        $('#commonProblemMessage').show();
        $('#problemIdentificationDeviceId').hide();

        $(bulkElements.modalProblemIdentification).modal("show");
    }
    
    saveProblem() {
        validateForm($(bulkElements.formProblemIdentificationModal));

        if ($(bulkElements.formProblemIdentificationModal).valid() == false) {
            return false;
        }

        if (this.isInCommonMode) {
    
            let currentDataSource = window.state.batches[window.state.currentBatchIndex].dataSource;
    
            for (let i = 0; i < currentDataSource.data().length; i++) {
                let item = currentDataSource.data()[i];
    
                item.set('problemId', $('#problemId').val());
                item.set('problemText', $('#problemId :selected').text());
                item.set('problemDescription', $('#problemDescription').val());
                item.set('whatITried', $('#whatITried').val());
                item.set('isReasonSet', true);
            }

            $(bulkElements.incompleteProblemsListMessage).hide();
        } else if (this.currentSelectedItem) {
            this.currentSelectedItem.set('problemId', $('#problemId').val());
            this.currentSelectedItem.set('problemText', $('#problemId :selected').text());
            this.currentSelectedItem.set('problemDescription', $('#problemDescription').val());
            this.currentSelectedItem.set('whatITried', $('#whatITried').val());
            this.currentSelectedItem.set('isReasonSet', true);

            this.checkForUnpopulatedProblems(false);
        }

        $(bulkElements.modalProblemIdentification).modal("hide");

        $('#problemId').val('');
        $('#problemDescription').val('');
        $('#whatITried').val('');
    }

    checkForUnpopulatedProblems(showErrorIfHidden) {
        let devicesWithoutProblemSpecified = window.state.batches[window.state.currentBatchIndex].dataSource.data().filter(i => !i.isReasonSet);

        let allDeviceProblemsArePopulated = devicesWithoutProblemSpecified.length === 0;

        if (allDeviceProblemsArePopulated) {
            $(bulkElements.incompleteProblemsListMessage).hide();
        }
        else if(showErrorIfHidden) {
            $(bulkElements.incompleteProblemsListMessage).show();
        }

        return allDeviceProblemsArePopulated;
    }
}