import axios from "axios";
import { elements, stripePaymentIntentServiceUrl } from "../../config";
import { bulkElements } from "../../configBatch";

export default class ServiceOrder {
    constructor(stripe) {
        this.stripe = stripe;
        this.result = {};
    }

    async postNewPaymentIntent(incidentNumber, amount, currency, depositRequired, customerEmail, customerId) {
        try {
            const paymentRequest = {
                amount: Number(amount),
                currency: currency,
                email: customerEmail,
                incidentNumber: incidentNumber,
                depositRequired: depositRequired,
                customerId: customerId
            };

            const options = {
                headers: { 'Content-Type': 'application/json' }
            };
            const response = await axios.post(stripePaymentIntentServiceUrl, JSON.stringify(paymentRequest), options);
            return response.data;
        }
        catch (error) {
            throw error;
        }
    }

    async createPaymentIntent() {
        try {
            let style = {
                base: {
                    color: "#32325d",
                    fontFamily: 'Arial, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                        color: "#32325d"
                    }
                },
                invalid: {
                    fontFamily: 'Arial, sans-serif',
                    color: "#fa755a",
                    iconColor: "#fa755a"
                }
            };
            let card = this.stripe.elements().create("card", { style: style });

            // Stripe injects an iframe into the DOM
            window.state.CardElement = card;
            card.mount(elements.cardElement);
            card.on("change", function (event) {
                if (event.complete) {
                    // enable payment button
                    $(bulkElements.btnFinishPaymentInformation).attr('disabled', false);
                    $(elements.cardError).text("");
                } else {
                    $(bulkElements.btnFinishPaymentInformation).attr('disabled', true);

                    $(elements.cardError).text(event.error ? event.error.message : "");
                }
            });

        } catch (error) {
            throw error;
        }
    }

    async createPaymentMethod(cardElement, billingDetails) {
        try {
            let paymentData;
            if (cardElement) {
                let fullCustomerName = '';
                if (billingDetails && billingDetails?.customer.fullName) {
                    fullCustomerName = billingDetails?.customer.fullName
                }
                else if (billingDetails?.customer.firstName && billingDetails?.customer.lastName) {
                    fullCustomerName = billingDetails?.customer.firstName + " " + billingDetails?.customer.lastName;
                }

                paymentData = {
                    type: 'card',
                    card: window.state.CardElement,
                    billing_details: {
                        address: {
                            city: billingDetails?.customer.address.city,
                            country: billingDetails?.customer.address.country,
                            line1: billingDetails?.customer.address.address1,
                            line2: billingDetails?.customer.address.address2,
                            postal_code: billingDetails?.customer.address.zipcode,
                            state: billingDetails?.customer.address.state
                        },
                        email: billingDetails?.customer.email,
                        name: fullCustomerName,
                        phone: billingDetails?.customer.phoneNumber
                    }
                };
            }

            const response = await this.stripe.createPaymentMethod(paymentData);
            if (response.error) {
                throw new Error(response.error.message);
            }
            else {
                const paymentMethodId = response.paymentMethod?.id;
                sessionStorage.setItem("PaymentMethodId", paymentMethodId);
            }
            
        } catch (error) {
            throw error;
        }
    }

    async isPaymentSuccessful(response, paymentMethodId) {
        if (response.error) {
            throw new Error(response.error.message);
        }
        else {
            sessionStorage.setItem("PaymentMethodId", paymentMethodId);
        }
    }

    async confirmPayment(incidentNumbers) {
        try {
            let result, customerId;
            let paymentMethodId = sessionStorage.getItem("PaymentMethodId");
            let contactInformation = JSON.parse(sessionStorage.getItem("contactInformation"));

            let selectedServiceOption = window.state.selectedServiceOption;

            let shippingData = {
                address: {
                    city: contactInformation?.customer.address.city,
                    country: contactInformation?.customer.address.country,
                    line1: contactInformation?.customer.address.address1,
                    line2: contactInformation?.customer.address.address2,
                    postal_code: contactInformation?.customer.address.zipcode,
                    state: contactInformation?.customer.address.state
                },
                name: contactInformation?.customer.firstName + " " + contactInformation?.customer.lastName,
                phone: contactInformation?.customer.phoneNumber
            };

            const confirmPaymentMethodParams = {
                payment_method: paymentMethodId,
                shipping: shippingData
            };

            // Calls stripe.confirmCardPayment
            // If the card requires authentication Stripe shows a pop-up modal to
            // prompt the user to enter authentication details without leaving your page.

            //Post new payment intent for shipping costs
            if (Number(window.state.totalShippingPrice) > 0) {
                let shippingPaymentIntentResponse = await this.postNewPaymentIntent(incidentNumbers, window.state.totalShippingPrice,
                    selectedServiceOption.currency, false, contactInformation?.customer.email);
                customerId = shippingPaymentIntentResponse.customerId;
                const responseShippingOrder = await this.stripe.confirmCardPayment(shippingPaymentIntentResponse.clientSecret, confirmPaymentMethodParams);
                let resultShippingOrder = await this.isPaymentSuccessful(responseShippingOrder, paymentMethodId);
                result = resultShippingOrder;
            }

            if (Number(window.state.totalDeposit) > 0) {
                //Post new payment intent for deposit costs
                let depositPaymentIntentResponse = await this.postNewPaymentIntent(incidentNumbers, window.state.totalDeposit,
                    selectedServiceOption.currency, true, contactInformation?.customer.email, customerId);
                const responseDepositOrder = await this.stripe.confirmCardPayment(depositPaymentIntentResponse.clientSecret, confirmPaymentMethodParams);
                await this.isPaymentSuccessful(responseDepositOrder, paymentMethodId);
            }

            return result;

        } catch (error) {
            throw error;
        }
    }
}