import { showScreen, screenIds } from "./navigation";
import { removeLoader } from "../base";

export function validateForm(form) {
    jQuery.validator.addMethod("defaultInvalid", function (value, element) {
        return !(element.value === element.defaultValue);
    }, window.batchResources.validation.defaultOption);

    jQuery.validator.addMethod("phoneAll", function (phone_number, element) {
        phone_number = phone_number.replace(/\s+/g, "");
        return this.optional(element) || phone_number.length > 9 &&
            phone_number.match(/^((\+[1-9]{1,4}[ \-\.]*)|(\([0-9]{2,3}\)[ \-\.]*)|([0-9]{2,4})[ \-\.]*)*?[0-9]{3,4}?[ \-\.]*[0-9]{3,4}?$/);
    }, window.batchResources.validation.phoneNumber);

    form.validate({
        normalizer: function (value) {
            // Trim the value of every element
            return $.trim(value);
        },
        errorPlacement: function errorPlacement(error, element) {
            element.after(error);
        },
        rules: {
            'customer[emailConfirm]': {
                equalTo: '#email',
                email: true
            }
        }
    }).settings.ignore = ":disabled,:hidden";
}

export function clearFormErrors(form){
    if (!form) return;

    form.validate().resetForm();
    form.find('.error').removeClass('error');
}

export function showError(){
    let message = '';

    if (!arguments?.length) {
        message += `<p>${window.batchResources.unableToProcessReplacementMessage}</p>`;
        message += `<p>${window.batchResources.cleanCacheAndTryAgainMessage}</p>`;
        message += `<p>${window.batchResources.contactSupportIfIssuePersistMessage}</p>`;
    }
    else {
        for (let i = 0; i < arguments.length; i++) {
             message += `<p>${arguments[i]}</p>`;
        }
    }

    $('.alert .error-message').html(message);

    showScreen(screenIds.error);

    removeLoader();
}