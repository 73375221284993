//populate select with result
export const populateSelectWithCountries = (countries, parentContainerSelector) => {
  let countrySelect = parentContainerSelector ? $(`${parentContainerSelector} #country`) : $("#country");

  for (let i = 0; i < countries.length; i++) {
    countrySelect.append(
      '<option class="option" value="' +
        countries[i].isocode +
        '">' +
        countries[i].name +
        "</option>"
    );
  }
};

//populate select with result
export const populateAllProblemSelectors = (activeProblems) => {
    $('.js-problem-id').each(function(i, element) {
        populateProblemSelector(activeProblems, element);
    });
};

const populateProblemSelector = (activeProblems, targetElement) => {
    for (let i = 0; i < activeProblems.length; i++) {
        $(targetElement).append(
            '<option class="option" value="' +
            activeProblems[i].id +
            '">' +
            activeProblems[i].problemDescription +
            "</option>"
        );
    }
}

export const populateSelectWithActiveSymptoms = (activeProblems) => {

    let symptomsDescriptionSelect = $("#symptomId");
    for (let i = 0; i < activeProblems.length; i++) {

        let problem = activeProblems[i]
        if (problem.symptoms != undefined) {
            let symptoms = problem.symptoms;
            for (let k = 0; k < symptoms.length; k++) {
                symptomsDescriptionSelect.append(
                    `<option value="${problem.id}"` +
                    'style="display: none;' +
                    '">' +
                    symptoms[k].description +
                    "</option>"
                );
            }
        }
    }
}
