import { elements } from "../../config";
import { bulkElements, batchIds } from "../../configBatch";

import { showError } from "../base-batch";
import { showLoader, removeLoader } from "../../base";

import ServiceOptions from "../models/ServiceOptions";
import {
    planNames,
    HideAllServiceOptions,
    PopulateShippingOptions,
    ShowServiceOption,
    OnShippingOptionSelect,
    DisplayServiceDetail
    } from "./serviceOptionsView";


export function initReplacementRequestView() {
    // Setting a new instance of Service Options on every load of the screen
    window.state.serviceOptionsModel = new ServiceOptions();

    //Service Options select
    $(bulkElements.formReplacementRequest).find("input[name='serviceOption']").click(onServiceOptionChange);

    $(elements.processingSelectWrapper).find('select').change(function (event) {
        OnShippingOptionSelect($(event.target));
    });
}


async function onServiceOptionChange(event) {
    try {
        showLoader();
        $(elements.serviceDetailsWrapper).hide();

        let serviceOptionName = $(event.target).val();
        let serialNumbers = window.state.batches[window.state.currentBatchIndex].dataSource.data().map((item) => {return item.Product.SerialNumber});

        await PopulateShippingOptions(serviceOptionName, serialNumbers);

        removeLoader();
    } catch (e) {
        showError();
    }
}

export async function loadReplacementRequestView() {
    let serialNumbers = window.state.batches[window.state.currentBatchIndex].dataSource.data().map((item) => {return item.Product.SerialNumber;});

    HideAllServiceOptions();

    let planName = '';
    switch (window.state.batches[window.state.currentBatchIndex].batchId) {
        case batchIds.premium:
            planName = planNames.SCP;
            break;
        case batchIds.accidental:
            planName = planNames.AE;
            break;
        case batchIds.standard:
        default:
            planName = planNames.STD;
            break;
    }

    sessionStorage.setItem('PlanName', planName);

    if (!window.state.serviceOptionsModel.result.storeLocation) {
        //Set Socket Store Location by user IP address
        await getStoreLocationByUserIPAddress();
    }

    //Show Service Options according to device warranty
    await ShowServiceOption(planName, serialNumbers);
}

export function updateReplacementRequestNextButton(){
    if (window.state.currentBatchIndex < window.state.batches.length - 1) {
        $(bulkElements.btnFinishReplacementRequest).val(window.batchResources.continueToNextBatchLabel);
    }
    else {
        $(bulkElements.btnFinishReplacementRequest).val(window.batchResources.continueToShippingLabel);
    }
}

//Service Options View functions
const getStoreLocationByUserIPAddress = async () => {
    try {
        await window.state.serviceOptionsModel.getSocketStoreLocation();
    } catch (error) {
        throw error;
    }
};
