import axios from "axios";
import { countriesUrl, problemsUrl, currentCulture } from "../config";

export default class CountriesAndProblems {
    constructor() {
        this.result = {};
    }

    async getProblems() {

        let url = problemsUrl;

        if (currentCulture) {
            url += '?lang=' + currentCulture;
        }

        try {
            const problems = await axios.get(url);

            this.result.problems = problems.data;
        } catch (error) {
            throw Error(error);
        }
    }

    async getCountries() {
        try {
            const countries = await axios.get(countriesUrl);

            this.result.countries = countries.data;
        } catch (error) {
            throw Error(error);
        }
    }
}